<template>
  <div class="user-profile">
    <section class="panel is-primary">
      <h1 class="title is-6 panel-heading mb-0">Данные профиля</h1>
      <div class="box">
        <div class="columns">
          <div class="column">
            <h6>Фамилия:</h6>
            <p>{{ userData.lastName }}</p>
          </div>
          <div class="column">
            <h6>Имя:</h6>
            <p>{{ userData.firstName }}</p>
          </div>
          <div class="column">
            <h6>Отчество:</h6>
            <p>{{ userData.patronymic }}</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h6>Роль:</h6>
            <p>{{ convertRole(userData.role) }}</p>
          </div>
          <div class="column">
            <h6>Email:</h6>
            <p>{{ userData.email }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  data() {
    return {
      userData: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    user(data) {
      this.userData = data;
    },
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  methods: {
    convertRole(role) {
      let roleText = '';

      switch (Number(role)) {
        case 1:
          roleText = 'Супер-админ';
          break;
        case 2:
          roleText = 'Администратор';
          break;
        case 3:
          roleText = 'Сотрудник';
          break;

        default:
          break;
      }

      return roleText;
    },
  },
};
</script>

<style lang="scss">
.user-profile {
  h6 {
    font-weight: bold;
  }
}
</style>
